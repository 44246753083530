/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo } from 'react'
import _ from 'lodash'

import { useGlobalState } from '@/globalStore/globalStore'

/** 国家值:国家名称 */
export const useCOUNTRY_NAME_MAP = (): { [code: string]: string | undefined } => {
  const lang = useGlobalState(s => s.lang)
  return useMemo(() => _.mapValues(data, obj => obj[lang]), [lang])
}

/** 国家选项列表 */
export const useCOUNTRY_OPTS = (): { value: string; label: string }[] => {
  const lang = useGlobalState(s => s.lang)
  return useMemo(
    () =>
      Object.entries(data).map(([value, obj]) => ({
        value,
        label: obj[lang],
      })),
    [lang],
  )
}

const data = {
  AF: {
    en: `Afghanistan`,
    zh: `阿富汗`,
  },
  AL: {
    en: `Albania`,
    zh: `阿尔巴尼亚`,
  },
  DZ: {
    en: `Algeria`,
    zh: `阿尔及利亚`,
  },
  AS: {
    en: `American Samoa`,
    zh: `美属萨摩亚`,
  },
  AD: {
    en: `Andorra`,
    zh: `安道尔`,
  },
  AO: {
    en: `Angola`,
    zh: `安哥拉`,
  },
  AI: {
    en: `Anguilla`,
    zh: `安圭拉`,
  },
  AQ: {
    en: `Antarctica`,
    zh: `南极洲`,
  },
  AG: {
    en: `Antigua and Barbuda`,
    zh: `安提瓜和巴布达`,
  },
  AR: {
    en: `Argentina`,
    zh: `阿根廷`,
  },
  AM: {
    en: `Armenia`,
    zh: `亚美尼亚`,
  },
  AW: {
    en: `Aruba`,
    zh: `阿鲁巴`,
  },
  AU: {
    en: `Australia`,
    zh: `澳大利亚`,
  },
  AT: {
    en: `Austria`,
    zh: `奥地利`,
  },
  AZ: {
    en: `Azerbaijan`,
    zh: `阿塞拜疆`,
  },
  BS: {
    en: `Bahamas`,
    zh: `巴哈马`,
  },
  BH: {
    en: `Bahrain`,
    zh: `巴林`,
  },
  BD: {
    en: `Bangladesh`,
    zh: `孟加拉国`,
  },
  BB: {
    en: `Barbados`,
    zh: `巴巴多斯`,
  },
  BY: {
    en: `Belarus`,
    zh: `白俄罗斯`,
  },
  BE: {
    en: `Belgium`,
    zh: `比利时`,
  },
  BZ: {
    en: `Belize`,
    zh: `伯利兹`,
  },
  BJ: {
    en: `Benin`,
    zh: `贝宁`,
  },
  BM: {
    en: `Bermuda`,
    zh: `百慕大`,
  },
  BT: {
    en: `Bhutan`,
    zh: `不丹`,
  },
  BO: {
    en: `Bolivia (Plurinational State of)`,
    zh: `玻利维亚`,
  },
  BA: {
    en: `Bosnia and Herzegovina`,
    zh: `波黑`,
  },
  BW: {
    en: `Botswana`,
    zh: `博茨瓦纳`,
  },
  BV: {
    en: `Bouvet Island`,
    zh: `布韦岛`,
  },
  BR: {
    en: `Brazil`,
    zh: `巴西`,
  },
  IO: {
    en: `British Indian Ocean Territory`,
    zh: `英属印度洋领地`,
  },
  VG: {
    en: `Virgin Islands (British)`,
    zh: `英属维尔京群岛`,
  },
  BN: {
    en: `Brunei Darussalam`,
    zh: `文莱`,
  },
  BG: {
    en: `Bulgaria`,
    zh: `保加利亚`,
  },
  BF: {
    en: `Burkina Faso`,
    zh: `布基纳法索`,
  },
  MM: {
    en: `Myanmar`,
    zh: `缅甸`,
  },
  BI: {
    en: `Burundi`,
    zh: `布隆迪`,
  },
  CV: {
    en: `Cabo Verde`,
    zh: `佛得角`,
  },
  KH: {
    en: `Cambodia`,
    zh: `柬埔寨`,
  },
  CM: {
    en: `Cameroon`,
    zh: `喀麦隆`,
  },
  CA: {
    en: `Canada`,
    zh: `加拿大`,
  },
  KY: {
    en: `Cayman Islands`,
    zh: `开曼群岛`,
  },
  CF: {
    en: `Central African Republic`,
    zh: `中非`,
  },
  TD: {
    en: `Chad`,
    zh: `乍得`,
  },
  CL: {
    en: `Chile`,
    zh: `智利`,
  },
  CN: {
    en: `China`,
    zh: `中华人民共和国`,
  },
  CX: {
    en: `Christmas Island`,
    zh: `圣诞岛`,
  },
  CC: {
    en: `Cocos (Keeling) Islands`,
    zh: `科科斯（基林）群岛`,
  },
  CO: {
    en: `Colombia`,
    zh: `哥伦比亚`,
  },
  KM: {
    en: `Comoros`,
    zh: `科摩罗`,
  },
  CD: {
    en: `Congo (Democratic Republic of the)`,
    zh: `刚果民主共和国`,
  },
  CG: {
    en: `Congo`,
    zh: `刚果共和国`,
  },
  CK: {
    en: `Cook Islands`,
    zh: `库克群岛`,
  },
  CR: {
    en: `Costa Rica`,
    zh: `哥斯达黎加`,
  },
  CI: {
    en: `Côte d'Ivoire`,
    zh: `科特迪瓦`,
  },
  HR: {
    en: `Croatia`,
    zh: `克罗地亚`,
  },
  CU: {
    en: `Cuba`,
    zh: `古巴`,
  },
  CW: {
    en: `Curaçao`,
    zh: `库拉索`,
  },
  CY: {
    en: `Cyprus`,
    zh: `塞浦路斯`,
  },
  CZ: {
    en: `Czechia`,
    zh: `捷克`,
  },
  DK: {
    en: `Denmark`,
    zh: `丹麦`,
  },
  DJ: {
    en: `Djibouti`,
    zh: `吉布提`,
  },
  DM: {
    en: `Dominica`,
    zh: `多米尼克`,
  },
  DO: {
    en: `Dominican Republic`,
    zh: `多米尼加`,
  },
  EC: {
    en: `Ecuador`,
    zh: `厄瓜多尔`,
  },
  EG: {
    en: `Egypt`,
    zh: `埃及`,
  },
  SV: {
    en: `El Salvador`,
    zh: `萨尔瓦多`,
  },
  GQ: {
    en: `Equatorial Guinea`,
    zh: `赤道几内亚`,
  },
  ER: {
    en: `Eritrea`,
    zh: `厄立特里亚`,
  },
  EE: {
    en: `Estonia`,
    zh: `爱沙尼亚`,
  },
  ET: {
    en: `Ethiopia`,
    zh: `埃塞俄比亚`,
  },
  FK: {
    en: `Falkland Islands (Malvinas)`,
    zh: `福克兰群岛`,
  },
  FO: {
    en: `Faroe Islands`,
    zh: `法罗群岛`,
  },
  FJ: {
    en: `Fiji`,
    zh: `斐济`,
  },
  FI: {
    en: `Finland`,
    zh: `芬兰`,
  },
  FR: {
    en: `France`,
    zh: `法国`,
  },
  FX: {
    en: `N/A`,
    zh: `法国本土`,
  },
  GF: {
    en: `French Guiana`,
    zh: `法属圭亚那`,
  },
  PF: {
    en: `French Polynesia`,
    zh: `法属波利尼西亚`,
  },
  TF: {
    en: `French Southern Territories`,
    zh: `法属南部和南极领地`,
  },
  GA: {
    en: `Gabon`,
    zh: `加蓬`,
  },
  GM: {
    en: `Gambia`,
    zh: `冈比亚`,
  },
  GE: {
    en: `Georgia`,
    zh: `格鲁吉亚`,
  },
  DE: {
    en: `Germany`,
    zh: `德国`,
  },
  GH: {
    en: `Ghana`,
    zh: `加纳`,
  },
  GI: {
    en: `Gibraltar`,
    zh: `直布罗陀`,
  },
  GR: {
    en: `Greece`,
    zh: `希腊`,
  },
  GL: {
    en: `Greenland`,
    zh: `格陵兰`,
  },
  GD: {
    en: `Grenada`,
    zh: `格林纳达`,
  },
  GP: {
    en: `Guadeloupe`,
    zh: `瓜德罗普`,
  },
  GU: {
    en: `Guam`,
    zh: `关岛`,
  },
  GT: {
    en: `Guatemala`,
    zh: `危地马拉`,
  },
  GG: {
    en: `Guernsey`,
    zh: `根西`,
  },
  GN: {
    en: `Guinea`,
    zh: `几内亚`,
  },
  GW: {
    en: `Guinea-Bissau`,
    zh: `几内亚比绍`,
  },
  GY: {
    en: `Guyana`,
    zh: `圭亚那`,
  },
  HT: {
    en: `Haiti`,
    zh: `海地`,
  },
  HM: {
    en: `Heard Island and McDonald Islands`,
    zh: `赫德岛和麦克唐纳群岛`,
  },
  VA: {
    en: `Holy See`,
    zh: `梵蒂冈`,
  },
  HN: {
    en: `Honduras`,
    zh: `洪都拉斯`,
  },
  HK: {
    en: `Hong Kong`,
    zh: `香港`,
  },
  HU: {
    en: `Hungary`,
    zh: `匈牙利`,
  },
  IS: {
    en: `Iceland`,
    zh: `冰岛`,
  },
  IN: {
    en: `India`,
    zh: `印度`,
  },
  ID: {
    en: `Indonesia`,
    zh: `印度尼西亚`,
  },
  IR: {
    en: `Iran (Islamic Republic of)`,
    zh: `伊朗`,
  },
  IQ: {
    en: `Iraq`,
    zh: `伊拉克`,
  },
  IE: {
    en: `Ireland`,
    zh: `爱尔兰`,
  },
  IM: {
    en: `Isle of Man`,
    zh: `马恩岛`,
  },
  IL: {
    en: `Israel`,
    zh: `以色列`,
  },
  IT: {
    en: `Italy`,
    zh: `意大利`,
  },
  JM: {
    en: `Jamaica`,
    zh: `牙买加`,
  },
  JP: {
    en: `Japan`,
    zh: `日本`,
  },
  JE: {
    en: `Jersey`,
    zh: `泽西`,
  },
  JO: {
    en: `Jordan`,
    zh: `约旦`,
  },
  KZ: {
    en: `Kazakhstan`,
    zh: `哈萨克斯坦`,
  },
  KE: {
    en: `Kenya`,
    zh: `肯尼亚`,
  },
  KI: {
    en: `Kiribati`,
    zh: `基里巴斯`,
  },
  KP: {
    en: `Korea (Democratic People's Republic of)`,
    zh: `朝鲜`,
  },
  KR: {
    en: `Korea (Republic of)`,
    zh: `韩国`,
  },
  XK: {
    en: `N/A`,
    zh: `科索沃`,
  },
  KW: {
    en: `Kuwait`,
    zh: `科威特`,
  },
  KG: {
    en: `Kyrgyzstan`,
    zh: `吉尔吉斯斯坦`,
  },
  LA: {
    en: `Lao People's Democratic Republic`,
    zh: `老挝`,
  },
  LV: {
    en: `Latvia`,
    zh: `拉脱维亚`,
  },
  LB: {
    en: `Lebanon`,
    zh: `黎巴嫩`,
  },
  LS: {
    en: `Lesotho`,
    zh: `莱索托`,
  },
  LR: {
    en: `Liberia`,
    zh: `利比里亚`,
  },
  LY: {
    en: `Libya`,
    zh: `利比亚`,
  },
  LI: {
    en: `Liechtenstein`,
    zh: `列支敦士登`,
  },
  LT: {
    en: `Lithuania`,
    zh: `立陶宛`,
  },
  LU: {
    en: `Luxembourg`,
    zh: `卢森堡`,
  },
  MO: {
    en: `Macao`,
    zh: `澳门`,
  },
  MK: {
    en: `North Macedonia`,
    zh: `北马其顿`,
  },
  MG: {
    en: `Madagascar`,
    zh: `马达加斯加`,
  },
  MW: {
    en: `Malawi`,
    zh: `马拉维`,
  },
  MY: {
    en: `Malaysia`,
    zh: `马来西亚`,
  },
  MV: {
    en: `Maldives`,
    zh: `马尔代夫`,
  },
  ML: {
    en: `Mali`,
    zh: `马里`,
  },
  MT: {
    en: `Malta`,
    zh: `马耳他`,
  },
  MH: {
    en: `Marshall Islands`,
    zh: `马绍尔群岛`,
  },
  MQ: {
    en: `Martinique`,
    zh: `马提尼克`,
  },
  MR: {
    en: `Mauritania`,
    zh: `毛里塔尼亚`,
  },
  MU: {
    en: `Mauritius`,
    zh: `毛里求斯`,
  },
  YT: {
    en: `Mayotte`,
    zh: `马约特`,
  },
  MX: {
    en: `Mexico`,
    zh: `墨西哥`,
  },
  FM: {
    en: `Micronesia (Federated States of)`,
    zh: `密克罗尼西亚联邦`,
  },
  MD: {
    en: `Moldova (Republic of)`,
    zh: `摩尔多瓦`,
  },
  MC: {
    en: `Monaco`,
    zh: `摩纳哥`,
  },
  MN: {
    en: `Mongolia`,
    zh: `蒙古`,
  },
  ME: {
    en: `Montenegro`,
    zh: `黑山`,
  },
  MS: {
    en: `Montserrat`,
    zh: `蒙特塞拉特`,
  },
  MA: {
    en: `Morocco`,
    zh: `摩洛哥`,
  },
  MZ: {
    en: `Mozambique`,
    zh: `莫桑比克`,
  },
  NA: {
    en: `Namibia`,
    zh: `纳米比亚`,
  },
  NR: {
    en: `Nauru`,
    zh: `瑙鲁`,
  },
  NP: {
    en: `Nepal`,
    zh: `尼泊尔`,
  },
  NL: {
    en: `Netherlands`,
    zh: `荷兰`,
  },
  AN: {
    en: `N/A`,
    zh: `荷属安的列斯`,
  },
  NC: {
    en: `New Caledonia`,
    zh: `新喀里多尼亚`,
  },
  NZ: {
    en: `New Zealand`,
    zh: `新西兰`,
  },
  NI: {
    en: `Nicaragua`,
    zh: `尼加拉瓜`,
  },
  NE: {
    en: `Niger`,
    zh: `尼日尔`,
  },
  NG: {
    en: `Nigeria`,
    zh: `尼日利亚`,
  },
  NU: {
    en: `Niue`,
    zh: `纽埃`,
  },
  NF: {
    en: `Norfolk Island`,
    zh: `诺福克岛`,
  },
  MP: {
    en: `Northern Mariana Islands`,
    zh: `北马里亚纳群岛`,
  },
  NO: {
    en: `Norway`,
    zh: `挪威`,
  },
  OM: {
    en: `Oman`,
    zh: `阿曼`,
  },
  PK: {
    en: `Pakistan`,
    zh: `巴基斯坦`,
  },
  PW: {
    en: `Palau`,
    zh: `帕劳`,
  },
  PA: {
    en: `Panama`,
    zh: `巴拿马`,
  },
  PG: {
    en: `Papua New Guinea`,
    zh: `巴布亚新几内亚`,
  },
  PY: {
    en: `Paraguay`,
    zh: `巴拉圭`,
  },
  PE: {
    en: `Peru`,
    zh: `秘鲁`,
  },
  PH: {
    en: `Philippines`,
    zh: `菲律宾`,
  },
  PN: {
    en: `Pitcairn`,
    zh: `皮特凯恩群岛`,
  },
  PL: {
    en: `Poland`,
    zh: `波兰`,
  },
  PT: {
    en: `Portugal`,
    zh: `葡萄牙`,
  },
  PR: {
    en: `Puerto Rico`,
    zh: `波尔图黎各`,
  },
  QA: {
    en: `Qatar`,
    zh: `卡塔尔`,
  },
  RE: {
    en: `Réunion`,
    zh: `留尼汪`,
  },
  RO: {
    en: `Romania`,
    zh: `罗马尼亚`,
  },
  TW: {
    en: `Taiwan, Province of China[note 1]`,
    zh: `台湾`,
  },
  RU: {
    en: `Russian Federation`,
    zh: `俄罗斯`,
  },
  RW: {
    en: `Rwanda`,
    zh: `卢旺达`,
  },
  BL: {
    en: `Saint Barthélemy`,
    zh: `圣巴泰勒米`,
  },
  SH: {
    en: `Saint Helena, Ascension and Tristan da Cunha	圣赫勒拿、阿森松和特里斯坦`,
    zh: `达库尼亚`,
  },
  KN: {
    en: `Saint Kitts and Nevis`,
    zh: `圣基茨和尼维斯`,
  },
  LC: {
    en: `Saint Lucia`,
    zh: `圣卢西亚`,
  },
  MF: {
    en: `Saint Martin (French part)`,
    zh: `法属圣马丁`,
  },
  VC: {
    en: `Saint Vincent and the Grenadines`,
    zh: `圣文森特和格林纳丁斯`,
  },
  WS: {
    en: `Samoa`,
    zh: `萨摩亚`,
  },
  SM: {
    en: `San Marino`,
    zh: `圣马力诺`,
  },
  ST: {
    en: `Sao Tome and Principe`,
    zh: `圣多美和普林西比`,
  },
  SA: {
    en: `Saudi Arabia`,
    zh: `沙特阿拉伯`,
  },
  SN: {
    en: `Senegal`,
    zh: `塞内加尔`,
  },
  RS: {
    en: `Serbia`,
    zh: `塞尔维亚`,
  },
  SC: {
    en: `Seychelles`,
    zh: `塞舌尔`,
  },
  SL: {
    en: `Sierra Leone`,
    zh: `塞拉利昂`,
  },
  SG: {
    en: `Singapore`,
    zh: `新加坡`,
  },
  SX: {
    en: `Sint Maarten (Dutch part)`,
    zh: `荷属圣马丁`,
  },
  SK: {
    en: `Slovakia`,
    zh: `斯洛伐克`,
  },
  SI: {
    en: `Slovenia`,
    zh: `斯洛文尼亚`,
  },
  SB: {
    en: `Solomon Islands`,
    zh: `所罗门群岛`,
  },
  SO: {
    en: `Somalia`,
    zh: `索马里`,
  },
  ZA: {
    en: `South Africa`,
    zh: `南非`,
  },
  GS: {
    en: `South Georgia and the South Sandwich Islands`,
    zh: `南乔治亚和南桑威奇群岛`,
  },
  SS: {
    en: `South Sudan`,
    zh: `南苏丹`,
  },
  ES: {
    en: `Spain`,
    zh: `西班牙`,
  },
  LK: {
    en: `Sri Lanka`,
    zh: `斯里兰卡`,
  },
  SD: {
    en: `Sudan`,
    zh: `苏丹`,
  },
  SR: {
    en: `Suriname`,
    zh: `苏里南`,
  },
  PM: {
    en: `Saint Pierre and Miquelon`,
    zh: `圣皮埃尔和密克隆`,
  },
  SZ: {
    en: `Eswatini`,
    zh: `斯威士兰`,
  },
  SE: {
    en: `Sweden`,
    zh: `瑞典`,
  },
  CH: {
    en: `Switzerland`,
    zh: `瑞士`,
  },
  SY: {
    en: `Syrian Arab Republic`,
    zh: `叙利亚`,
  },
  TJ: {
    en: `Tajikistan`,
    zh: `塔吉克斯坦`,
  },
  TZ: {
    en: `Tanzania, United Republic of`,
    zh: `坦桑尼亚`,
  },
  TH: {
    en: `Thailand`,
    zh: `泰国`,
  },
  TL: {
    en: `Timor-Leste`,
    zh: `东帝汶`,
  },
  TG: {
    en: `Togo`,
    zh: `多哥`,
  },
  TK: {
    en: `Tokelau`,
    zh: `托克劳`,
  },
  TO: {
    en: `Tonga`,
    zh: `汤加`,
  },
  TT: {
    en: `Trinidad and Tobago`,
    zh: `特立尼达和多巴哥`,
  },
  TN: {
    en: `Tunisia`,
    zh: `突尼斯`,
  },
  TR: {
    en: `Türkiye`,
    zh: `土耳其`,
  },
  TM: {
    en: `Turkmenistan`,
    zh: `土库曼斯坦`,
  },
  TC: {
    en: `Turks and Caicos Islands`,
    zh: `特克斯和凯科斯群岛`,
  },
  TV: {
    en: `Tuvalu`,
    zh: `图瓦卢`,
  },
  UG: {
    en: `Uganda`,
    zh: `乌干达`,
  },
  UA: {
    en: `Ukraine`,
    zh: `乌克兰`,
  },
  AE: {
    en: `United Arab Emirates`,
    zh: `阿联酋`,
  },
  GB: {
    en: `United Kingdom of Great Britain and Northern Ireland`,
    zh: `英国`,
  },
  US: {
    en: `United States of America`,
    zh: `美国`,
  },
  UM: {
    en: `United States Minor Outlying Islands`,
    zh: `美国本土外小岛屿`,
  },
  UY: {
    en: `Uruguay`,
    zh: `乌拉圭`,
  },
  UZ: {
    en: `Uzbekistan`,
    zh: `乌兹别克斯坦`,
  },
  VU: {
    en: `Vanuatu`,
    zh: `瓦努阿图`,
  },
  VE: {
    en: `Venezuela (Bolivarian Republic of)`,
    zh: `委内瑞拉`,
  },
  VN: {
    en: `Viet Nam`,
    zh: `越南`,
  },
  VI: {
    en: `Virgin Islands (U.S.)`,
    zh: `美属维尔京群岛`,
  },
  WF: {
    en: `Wallis and Futuna`,
    zh: `瓦利斯和富图纳`,
  },
  PS: {
    en: `Palestine, State of`,
    zh: `巴勒斯坦`,
  },
  EH: {
    en: `Western Sahara`,
    zh: `西撒哈拉`,
  },
}

import { useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import dayjs from 'dayjs'
import { DatePicker, GetProp, GetProps } from 'antd'

/** 单日期选择 */
export const FormItemDatePicker = (
  props: Omit<GetProps<typeof DatePicker>, 'value' | 'onChange'> & {
    value?: string
    onChange?: (value: string | undefined) => void
  },
) => {
  const { value, onChange, ...rest } = props

  const newValue = useMemo(() => {
    if (!value) return undefined
    return dayjs(value)
  }, [value])

  const handleChange = useMemoizedFn<GetProp<typeof DatePicker, 'onChange'>>((_d, str) => {
    if (!onChange) return
    let val: any
    if (str) val = str
    onChange(val)
  })

  return <DatePicker {...rest} value={newValue} onChange={handleChange} />
}
